import Vue from 'vue'
import { Button, Checkbox, FormModel, Input, Modal, Message, Select, Skeleton, Spin, Radio, Result, Tabs, Icon, Tooltip } from 'ant-design-vue'

Vue.use(Button)
Vue.use(Checkbox)
Vue.use(FormModel)
Vue.use(Input)
Vue.use(Modal)
Vue.use(Message)
Vue.use(Select)
Vue.use(Skeleton)
Vue.use(Spin)
Vue.use(Radio)
Vue.use(Result)
Vue.use(Tabs)
Vue.use(Icon)
Vue.use(Tooltip)
// Vue.use(Collapse)

// 樣式設定在 assets/styles/antd.less
