<template>
  <!--全螢幕Loading-->
  <div v-if="isLoading" id="loading" class="fixed flex flex-col justify-center items-center h-full w-full opacity-100">
    <a-spin class=" z-index-1600" />
    <div class="h-2"></div>
    <span class=" z-index-1600">{{ description }}</span>
    <div id="loading-bg" class="absolute bg-white h-full w-full opacity-90 z-index-1500"></div>
  </div>
</template>
<script lang="ts">
export default {
  name: 'Loading',
  props: {
    isFull: {
      type: Boolean,
      default: true
    },
    isLoading: {
      type: Boolean,
      default: true
    },
    description: {
      type: String,
      default: '資料處理中'
    }
  },
  data() {
    return {}
  },
}
</script>
<style>
#loading {
  z-index: 1400;
}
.z-index-1600 {
  z-index: 1600;
}
.z-index-1500 {
  z-index: 1500;
}
</style>
