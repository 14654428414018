import Vue from 'vue'

Vue.filter('currency', function(val) {
    return val.toLocaleString('en-US')
})

Vue.filter('balance',(value, type) => {
    switch(type) {
        case 1:
            return `+${value}`
        case 2:
            return `-${value}`
    }
})