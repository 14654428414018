import axios from 'axios'
import store from '../store'
import auth from '../store/modules/auth'
import router from '@/router'

import message from 'ant-design-vue/es/message'

const service = axios.create({
  baseURL: process.env.VUE_APP_BASE_API + '/api/v1',
  timeout: 60000
})

// request interceptor
service.interceptors.request.use(
  config => {
    config.headers = {
      'Content-Type': 'application/json'
    }

    if (store.getters.isLogin) {
      config.headers.Authorization = `Bearer ${store.getters.token}`
    }

    return config
  },
  error => {
    console.log('error1', error)
    auth.context('logout')
    message.error('發生錯誤')
    return Promise.reject(error)
  }
)

// response interceptor
service.interceptors.response.use(
  response => {
    return response.data
  },
  error => {
    console.log('service.interceptors.response ERROR')
    console.log('error.response', error.response)

    switch (error.response.status) {
      case 401: //認證有問題
        console.log('認證錯誤,請重新登入')
        message.error('認證錯誤,請重新登入')
        store.dispatch('logout')
        router.push('/login')
        break

      default:
        message.error('發生錯誤')
        break
    }

    return Promise.reject(error)
  }
)

const formService = axios.create({
  baseURL: process.env.VUE_APP_BASE_API + '/api/v1',
  timeout: 60000
})

formService.interceptors.request.use(
  config => {
    config.headers = {
      'Content-Type': 'multipart/form-data'
    }

    if (store.getters.isLogin) {
      config.headers.Authorization = `Bearer ${store.getters.token}`
    }

    return config
  },
  error => {
    return Promise.reject(error)
  }
)

formService.interceptors.response.use(
  response => {
    return response.data
  },
  error => {
    console.error('request.js err' + error) // for debug
    if (error.response.data) {
     message.error(error.response.data.msg)
    }
    return Promise.reject(error)
  }
)

export const formRequest = formService

export default service
