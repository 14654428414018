import router from '../../router'
import { GetCart, PostCart, DeleteCart, PatchCart, PostPaymentCart } from '@/apis/Cart'
import store from '@/store'
import message from 'ant-design-vue/es/message'

export default {
  state: () => ({
    items: [],
    paymentHtml: ""
  }),
  mutations: {
    SET_CART(state, payload) {
      state.items = [...payload]
    },
    SET_CART_QUANTITY(state, payload) {
      state.cartQuantity = payload
    },
    SET_PAYMENT_HTML(state, payload) {
      state.paymentHtml = payload
    }
  },
  actions: {
    //24 取得購物車 F5時開在背景不要loading
    async getCartDataWithoutLoading({ commit }, data) {
      try {
        const res = await GetCart(data)
        if (res.err === 0) {
          commit('SET_CART', res.msg.items)
        }
        else if (res.err === 1) {
          message.error(res.msg)
        }
      } catch (err) {
        console.log('api24 catch')
        message.error(err)
      }
    },
    //24 取得購物車
    async getCartData({ commit }, data) {
      store.dispatch('setLoading', true)
      try {
        const res = await GetCart(data)
        if (res.err === 0) {
          commit('SET_CART', res.msg.items)
          store.dispatch('setLoading', false)
        }
        else if (res.err === 1) {
          message.error(res.msg)
          await store.dispatch('setLoading', false)
        }
      } catch (err) {
        console.log('api24 catch')
        store.dispatch('setLoading', false)
        message.error(err)
      }
    },
    //25 購物車新增商品
    async addCartData({ commit }, formData) {
      await store.dispatch('setLoading', true)
      try {
        const res = await PostCart(formData)
        if (res.err === 0) {
          store.dispatch('setLoading', false)
          commit('SET_CART', res.msg.items)
          message.success('成功新增到購物車')
          await store.dispatch('setLoading', false)
        }
        else if (res.err === 1) {
          message.error(res.msg)
          await store.dispatch('setLoading', false)
        }
      } catch (err) {
        store.dispatch('setLoading', false)
        message.error(err)
      }
    },
    //26 購物商品更新
    async renewCart({ commit }, { id, formData }) {
      console.log(formData)
      try {
        const res = await PatchCart(id, formData)
        if (res.err === 0) {
          commit('SET_CART', res.msg.items)
        }
        else if (res.err === 1) {
          message.error(res.msg)
        }
      } catch (err) {
        console.log(err)
      }
    },

    //27  購物車刪除商品
    async deleteCartData({ commit }, id) {
      store.dispatch('setLoading', true)
      try {
        const res = await DeleteCart(id)
        if (res.err === 0) {
          console.log('DeleteCar', res.msg.items)
          store.dispatch('setLoading', false)
          message.success('刪除成功')
          commit('SET_CART', res.msg.items)
        }
      } catch (err) {
        console.log('catch 購物車刪除商品', err)
        store.dispatch('setLoading', false)
        message.error(err)
      }
    },

    // 結帳
    async checkoutPayment({ commit }, { id, formData }) {
      store.dispatch('setLoading', true)
      try {
        const res = await PostPaymentCart(id, formData)

        if (res.err == 0) {
          store.dispatch('setLoading', false)
          console.log('製造form');
          console.log(res.msg)
          document.open('text/html');
          document.write(`
            <script>
              window.onpageshow = function(event) {
                if (event.persisted) {
                  window.location = '/'
                }
              }
            </script>
          `);
          document.write(res.msg);
          document.close();

          commit('SET_PAYMENT_HTML', res.msg)
          //router.push('/Success')
        }
        else if (res.err == 3) {
          console.log('COCO幣全額付款')
          router.push({ path: '/payment/success', query: { CustomField1: res.msg, RtnCode: 1, PaymentType: 'Cash.COCO' } })
        }
        else if (res.err == 4) {
          router.push({ path: '/payment/success', query: { CustomField1: res.msg, RtnCode: 2, PaymentType: 'Cash.COCO' } })
        }
        else if (res.err == 1) {
          store.dispatch('setLoading', false)
          message.error(res.msg)
          console.log(res.msg)
          router.push('/Fail')
        }
      } catch (err) {
        store.dispatch('setLoading', false)
        console.log(err)
      }
    },
    setCartQuantity({ commit }, payload) {
      commit('SET_CART_QUANTITY', payload)
    }
  },
  getters: {
    cartQuantity(state) {
      return state.items.length
    },
    items(state) {
      return state.items
    },
    paymentHtml(state) {
      return state.paymentHtml
    }
  }
}
