import { PostLog } from '@/apis/Log'

export default {
  state: () => ({}),
  getter: {},
  mutations: {},
  actions: {
    postLog(_, {code = 0, data = ' '}) {
      const formData = new FormData()
      formData.append('Code', code)
      formData.append('data', data)

      PostLog(formData)
    }
  }
}
