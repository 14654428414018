import router from '../../router'
import { PostLogin, PostRegister, PostVerify, PatchReset,PatchPasswordChange } from '@/apis/Account'
import * as root from '../index'
import message from 'ant-design-vue/es/message'
//import { Modal } from 'ant-design-vue';

export default {
  state: () => ({
    // token: sessionStorage.getItem('session-token') || localStorage.getItem('local-token'),
    token: localStorage.getItem('local-token'),
    rememberMe: null,
    mobileExists: false, //會員手機號碼存在 registerForm判斷modal視窗
    verifyTimestamp: '',
    verifyCocoroMember: false,
  }),
  mutations: {
    SET_REMEMBER_ME(state, payload) {
      state.rememberMe = payload
    },
    SET_TOKEN(state, token) {
      state.token = token
    },
    SET_MOBILE_EXISTS(state, token) {
      state.mobileExists = token
    },
    REMOVE_TOKEN(state) {
      state.token = ''
    },
    LOGIN(state, payload) {
      this.commit('SET_TOKEN', payload.token)
    },
    SET_VERIFTY_TIMESTAMP(state, payload) {
      state.verifyTimestamp = payload
    },
    SET_VERIFY_COCOROMEMBER(state, payload) {
      state.verifyCocoroMember = payload
    }
  },
  actions: {
    // 認證
    async getVerifySMS(_, data) {
      console.log('驗證手機...')
      try {
        const res = await PostVerify(data)
        if (res.err == 0) {
          console.log(res.msg)
          this.commit('SET_VERIFTY_TIMESTAMP',res.msg.timestamp)
        }
        if (res.err == 1) {
          this.commit('SET_MOBILE_EXISTS',true)
          message.error(res.msg)
        }
        //錯誤回傳400,會跳到server.js error
      } catch (err) {
        console.error('getVerifySMS error', err)
      }
    },

    // 註冊
    async register({ commit }, data) {
      root.default.commit('SET_LOADING', true)
      try {
        const res = await PostRegister(data)
        if (res.err == 0) {
          //註冊成功
          console.warn('debug remove local-token')
          await localStorage.removeItem('local-token')
          // await sessionStorage.setItem('session-token', res.msg.token)
          await localStorage.setItem('local-token', res.msg.token)
          await commit('LOGIN', res.msg)
          await root.default.commit('SET_LOADING', false)
          await message.success('註冊成功')
          await router.push({ name: 'home' })
        }
        if (res.err == 1) {
          message.error(res.msg)
          root.default.commit('SET_LOADING', false)
        }
        //錯誤回傳400,會跳到server.js error
      } catch (err) {
        console.error('auth err:', err)
        root.default.commit('SET_LOADING', false)
      }
    },

    // api登入
    async login({ commit }, data) {
      root.default.commit('SET_LOADING', true)
      try {
        const response = await PostLogin(data)
        if(response.err === 30) {
          root.default.commit('SET_LOADING', false)
          commit('SET_VERIFY_COCOROMEMBER', true)
        }
        else if (response.err === 0) {
          localStorage.setItem('local-token', response.msg.token)
          // 記住我
          console.log(response)
          if (root.default.state.auth.rememberMe) {
            // localStorage.setItem('local-token', response.msg.token)
            // console.log('remember-me, set-local-token')
            console.log('remember-me')
            localStorage.setItem('remember-me', response.msg.mobile)
          }
          // 不用記住我
          else if (!root.default.state.auth.rememberMe) {
            console.log('not remember-me')
            // console.warn('debug remove local-token')
            // localStorage.removeItem('local-token')
            // sessionStorage.setItem('session-token', response.msg.token)
            localStorage.removeItem('remember-me')
          }
          commit('LOGIN', response.msg)
          router.push({ name: 'home' })
          message.success('登入成功')
          root.default.commit('SET_LOADING', false)
        } else if (response.err === 1) {
          message.error('登入失敗')
          console.log(response.msg)
          root.default.commit('SET_LOADING', false)
        }
      } catch (err) {
        console.log(err)
        root.default.commit('SET_LOADING', false)
      }
    },
    // 登出
    logout({ dispatch }) {
      this.commit('REMOVE_TOKEN')
      this.commit('SET_REMEMBER_ME', false)
      dispatch('removeTokens')
      message.success('登出成功')
      router.push({ name: 'login' })
    },
    // 記得我
    setRememberMe({ commit }, payload) {
      commit('SET_REMEMBER_ME', payload)
    },
    // 清除cookie-token,session-token
    removeTokens() {
      console.warn('debug remove local-token')
      localStorage.removeItem('local-token')
      sessionStorage.removeItem('session-token')
      localStorage.removeItem('local-status')
      sessionStorage.removeItem('session-status')
      console.log('Logout successfully')
    },
    // 初次進入頁面時檢查有沒有token
    async checkTokens({ commit }) {
      if (await localStorage.getItem('local-token')) {
        const tokenData = localStorage.getItem('local-token')
        console.log('Local Token(記得我) found, continue..')
        await commit('SET_TOKEN', tokenData)
        root.default.dispatch('getUserWithoutLoading') //抓取個人資料
        root.default.dispatch('getCartDataWithoutLoading') //抓取購物車訂單資料
      } else if (await sessionStorage.getItem('session-token')) {
        const tokenData = sessionStorage.getItem('session-token')
        console.log('Session Token found, continue..')
        await commit('SET_TOKEN', tokenData)
        root.default.dispatch('getUserWithoutLoading') //抓取個人資料
        root.default.dispatch('getCartDataWithoutLoading') //抓取購物車訂單資料
      }
      return
    },
    // 忘記密碼
    async resetPassword(_, data) {
      console.log('忘記密碼重設...')
      root.default.commit('SET_LOADING', true)
      try {
        const res = await PatchReset(data)
        if (res.err == 0) {
          message.success('密碼重設成功')
          root.default.commit('SET_LOADING', false)
          router.push({ name: 'home' })
        }
        if (res.err == 1) {
          message.error('密碼重設失敗')
          root.default.commit('SET_LOADING', false)
        }
        //錯誤回傳400,會跳到server.js error
      } catch (err) {
        console.error('getVerifySMS error', err)
        root.default.commit('SET_LOADING', false)
      }
    },
    // 重設密碼
    async changePassword(_, data) {
      console.log('重設密碼...')
      root.default.commit('SET_LOADING', true)
      try {
        const res = await PatchPasswordChange(data)
        if (res.err == 0) {
          message.success('密碼重設成功')
          root.default.commit('SET_LOADING', false)
          router.push({ name: 'home' })
        }
        if (res.err == 1) {
          message.error('密碼重設失敗')
          root.default.commit('SET_LOADING', false)
        }
        //錯誤回傳400,會跳到server.js error
      } catch (err) {
        console.error('catch err', err)
        root.default.commit('SET_LOADING', false)
      }
    },
    setMobileExists({ commit }, payload){
      commit('SET_MOBILE_EXISTS',payload)
    },
    setVerifyCocoroMember({ commit }, payload) {
      commit('SET_VERIFY_COCOROMEMBER',payload)
    }
  },
  getters: {
    isLogin(state) {
      return !!state.token
    },
    token(state) {
      return state.token
    },
    hasToken(state) {
      return !!state.token
    },
    isMobileExists(state) {
      return state.mobileExists
    },
    verifyTimestamp(state){
      return state.verifyTimestamp
    },
    verifyCocoroMember(state) {
      return state.verifyCocoroMember
    }
  },
  namespace: true
}
