import formRequest from '@/utils/request';

/**
 * @description 21.取得地址清單
 * @param {Object} data
 * @param {number} data.type 地址類別 - 0:宅配 1:7-11 2:全家
 */
export function GetAddresses(data) {
  return formRequest({
    url: '/addresses',
    method: 'get',
    data
  });
}

/**
 * @description 22.新增地址
 * @param {object} data
 * @param {number} data.type 地址類別 - 0:宅配 1:7-11 2:全家
 * @param {string} data.name  收件人姓名
 * @param {string} data.mobile 收件人電話
 * @param {string} data.county 縣市
 * @param {string} data.district 鄉鎮市區
 * @param {string} data.detail 路街段巷弄號樓室
 * @param {string} data.store_id 門市代碼
 * @param {string} data.store_name 門市名稱
 */
 export function PostAddresses(data) {
  return formRequest({
    url: '/addresses',
    method: 'post',
    data
  });
}

/**
 * @description 23.修改地址
 * @param {number} id - 編輯地址id
 * @param {object} data
 * @param {number} data.type 地址類別 - 0:宅配 1:7-11 2:全家
 * @param {string} data.name  收件人姓名
 * @param {string} data.mobile 收件人電話
 * @param {string} data.county 縣市
 * @param {string} data.district 鄉鎮市區
 * @param {string} data.detail 路街段巷弄號樓室
 * @param {string} data.store_id 門市代碼
 * @param {string} data.store_name 門市名稱
 */
 export function PatchAddresses(id,data) {
  return formRequest({
    url: `/addresses/${id}`,
    method: 'patch',
    data
  });
}

/**
 * @description 24.刪除地址
 * @param {string} id
 */
export function DeleteAddresses(id) {
  return formRequest({
    url: `/addresses/${id}`,
    method: 'delete',
  });
}