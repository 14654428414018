import Vue from 'vue'
import Vuex from 'vuex'
import auth from './modules/auth'
import user from './modules/user'
import products from './modules/products'
import cart from './modules/cart'
import orders from './modules/orders'
import addresses from './modules/addresses'
import log from './modules/log'
import personal from './modules/personal'
import banner from './modules/banner'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    loading: false,
    windowWidth: 0
  },
  mutations:{
    SET_LOADING(state, payload) {
      state.loading = payload
    },
    SET_WINDOW_WIDTH(state, payload) {
      state.windowWidth = payload
    }
  },
  actions:{
    setLoading({ commit }, payload) {
      commit('SET_LOADING', payload)
    },
    setWindowWidth({commit}, payload) {
      commit('SET_WINDOW_WIDTH', payload)
    }
  },
  getters:{
    rootState(state) {
      return state
    },
    loading(state) {
      return state.loading
    },
    windowWidth(state) {
      return state.windowWidth
    },
    isMobile(state) {
      return state.windowWidth < 768
    }
  },
  modules: {
    auth,
    user,
    products,
    cart,
    orders,
    addresses,
    log,
    personal,
    banner
  },
})