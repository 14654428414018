import request, { formRequest } from '@/utils/request';

export function GetUser() {
  return request({ 
    url: '/user',
    method: 'get',
  });
}

export function UpdateUser(data) {
  return formRequest({ 
    url: '/user',
    method: 'patch',
    contentType: '',
    data
  });
}

export function GetUserBenefit() {
  return request({ 
    url: '/user/benefit',
    method: 'get',
    contentType: '',
  });
}

export function GetUserCoco() {
  return request({
    url: '/user/coco',
    method: 'get',
    contentType: '',
  });
}

export function GetUserCocoHistory(data) {
  return request({
    url: '/user/cocohistory',
    method: 'get',
    contentType: '',
    data
  });
}
