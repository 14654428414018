import request, { formRequest } from '@/utils/request';

/**
 * @description 登入
 * @param {Userid, pwd} data
 */
export function PostLogin(data) {
  return request({ 
    url: '/login',
    method: 'post',
    data
  });
}

export function PostRegister(data) {
  return formRequest({ 
    url: '/register',
    method: 'post',
    contentType: '',
    data
  });
}

/**
 * @description 手機驗證
 * @param {object} data
 * @param {string} data.mobile 手機號碼
 */
export function PostVerify(data) {
  return formRequest({
  url: '/verify',
  method: 'post',
  data
});
}


/**
 * @description 重設密碼
 * @param {object} data
 * @param {string} data.mobile 手機號碼
 * @param {string} data.password 密碼
 * @param {string} data.verify 手機簡訊認證碼
 */
 export function PatchReset(data) {
  return formRequest({
  url: '/password/reset',
  method: 'patch',
  data
});
}

/**
 * @description 登入後修改密碼密碼
 * @param {object} data
 * @param {string} data.old_password 舊密碼
 * @param {string} data.new_password 新密碼
 */
 export function PatchPasswordChange(data) {
  return formRequest({
  url: '/password/change',
  method: 'patch',
  data
});
}


