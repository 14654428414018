import request,{formRequest} from '@/utils/request'


// 商品分類清單
export function GetCategories(data) {
  return request({
    url: '/categories',
    method: 'get',
    data
  })
}

// 全部商品列表
export function GetProductsAll(data) {
  return formRequest({
    url: `/products/all`,
    method: 'get',
    params:data
  })
}

// 最新商品列表
export function GetProductsHot(data) {
  return request({
    url: '/products/hot',
    method: 'get',
    contentType: '',
    params: data
  })
}

// 取得商品內容
export function GetProductDetail(id, flag) {
  return request({
    url: `/products/${id}/${flag}`,
    method: 'get',
  })
}

// 商品問答清單
export function GetProductQuestions(id) {
  return request({
    url: `/products/${id}/questions`,
    method: 'get',
  })
}

// 提出商品問題
export function PostProductQuestions(id,data) {
  return formRequest({
    url: `/products/${id}/questions`,
    method: 'post',
    contentType: '',
    data
  })
}