import { GetCategories, GetProductsAll, GetProductsHot, GetProductDetail, GetProductQuestions, PostProductQuestions } from '@/apis/Products'
import store from '@/store'
import message from 'ant-design-vue/es/message'
import { browseProductList } from '@/utils/ga.js'
import router from '@/router';

export default {
  state: () => ({
    allProducts: [],
    hotProducts: [],
    productDetail: {},
    productQuestions: [],
    categories: [],
    minusOnePage: false
  }),
  mutations: {
    SET_CATEGORIES(state, payload) {
      state.categories = payload
    },
    SET_PRODUCTS_ALL(state, payload) {
      state.allProducts = [...state.allProducts, ...payload]
      // state.allProducts = payload
    },
    CLEAR_PRODUCTS_ALL(state) {
      state.allProducts = []
    },
    SET_PRODUCTS_HOT(state, payload) {
      state.hotProducts = [...state.hotProducts, ...payload]
    },
    CLEAR_PRODUCTS_HOT(state) {
      state.hotProducts = []
    },
    SET_PRODUCT_DETAIL(state, payload) {
      state.productDetail = payload
    },
    SET_PRODUCT_QUESTIONS(state, payload) {
      state.productQuestions = payload
    },
    CLEAN_PRODUCTS_STATES(state) {
      state.allProducts = []
      state.hotProducts = []
      state.productDetail = {}
      state.productQuestions = []
    },
    SET_MINUS_ONE_PAGE(state) {
      state.minusOnePage = !state.minusOnePage
    },
    SET_GA_BROWSE_PRODCUTS(state, payload) {
      console.log(payload)
      this
      const productList = payload.map(el => {
        return {
          name: el.name,
          id: el.id,
          price: el.price.max,
        }})
      this._vm.$gtm.trackEvent(browseProductList(productList))
    }
  },
  actions: {
    // 商品分類清單
    async getCategories({ commit }) {
      try {
        const res = await GetCategories()
        if (res.err === 0) {
          store.dispatch('setLoading', false)
          commit('SET_CATEGORIES', res.msg)
        } else {
          //取不到分類不要顯示message錯誤
          console.log(res.msg)
        }
      } catch (err) {
        console.log(err)
      }
    },
    // 全部 商品列表
    async getProductsAll({ commit }, data) {
      try {
        const res = await GetProductsAll(data)
        if (res.err === 0) {
          commit('SET_PRODUCTS_ALL', res.msg)
          commit('SET_GA_BROWSE_PRODCUTS', res.msg)
        } else {
          //取不到商品不要顯示message錯誤
          commit('SET_MINUS_ONE_PAGE')
          console.log(res.msg)
        }
      } catch (err) {
        console.log(err)
      }
    },
    // 全部 商品列表 先清除資料再打api pageZero沒有SET_MINUS_ONE_PAGE
    async getProductsAllPageZero({ commit }, data) {
      try {
        const res = await GetProductsAll(data)
        if (res.err === 0) {
          console.log('全部商品 msg:', res)
          await commit('CLEAR_PRODUCTS_ALL')
          await commit('SET_PRODUCTS_ALL', res.msg)
          await commit('SET_GA_BROWSE_PRODCUTS', res.msg)
        } else {
          console.log('全部商品 error:', res)
        }
      } catch (err) {
        console.log(err)
      }
    },
    // 清除全部 商品列表
    async clearProductsAll({ commit }) {
      commit('CLEAR_PRODUCTS_ALL')
    },

    // 當期 商品列表
    async getProductsHot({ commit }, data) {
      try {
        const res = await GetProductsHot(data)
        if ((res.err === 0) & (res.msg.length > 0)) {
          console.log('當期商品msg err == 0 , length > 0', res)
          commit('SET_PRODUCTS_HOT', res.msg)
          commit('SET_GA_BROWSE_PRODCUTS', res.msg)
          //當商品回傳為0時,page不要+1
          if (res.msg.length == 0) commit('SET_MINUS_ONE_PAGE')
        } else {
          //取不到商品不要顯示message錯誤
          commit('SET_MINUS_ONE_PAGE')
          console.log('當期商品msg', res.msg)
        }
      } catch (err) {
        console.log(err)
      }
    },
    // 當期 商品列表 先清除資料再打api pageZero沒有SET_MINUS_ONE_PAGE
    async getProductsHotPageZero({ commit }, data) {
      try {
        const res = await GetProductsHot(data)
        if ((res.err === 0) & (res.msg.length > 0)) {
          console.log('當期商品 msg', res)
          await commit('CLEAR_PRODUCTS_HOT')
          await commit('SET_PRODUCTS_HOT', res.msg)
          await commit('SET_GA_BROWSE_PRODCUTS', res.msg)

        } else {
          console.log('當期商品 error:', res)
        }
      } catch (err) {
        console.log(err)
      }
    },

    // 清除當期 商品列表
    async clearProductsHot({ commit }) {
      commit('CLEAR_PRODUCTS_HOT')
    },

    // 商品詳細
    async getDataProductDetail({ commit }, { id, flag }) {
      store.dispatch('setLoading', true)

      // api商品詳細
      try {
        const res = await GetProductDetail(id, flag)
        if (res.err === 0) {
          store.dispatch('setLoading', false)
          commit('SET_PRODUCT_DETAIL', res.msg)
        } else if (res.err !== 0) {
          store.dispatch('setLoading', false)
          message.error('找不到該商品')
          router.push('/404')
        }
      } catch (err) {
        store.dispatch('setLoading', false)
        message.error(err)
      }

      // api商品問答
      try {
        const resQA = await GetProductQuestions(id)
        if (resQA.err === 0) {
          store.dispatch('setLoading', false)
          commit('SET_PRODUCT_QUESTIONS', resQA.msg)
        } else if (resQA.err !== 0) {
          store.dispatch('setLoading', false)
          message.error(resQA.msg)
        }
      } catch (err) {
        store.dispatch('setLoading', false)
        message.error(err)
      }
    },

    // 問問題
    async postProductQuestions(_, { id, formData }) {
      store.dispatch('setLoading', true)
      // api
      try {
        console.log('postProductQuestions')
        const resPostQA = await PostProductQuestions(id, formData)
        if (resPostQA.err === 0) {
          console.log('resPostQA', resPostQA)
          store.dispatch('setLoading', false)
          message.success(`您已成功發問: ${resPostQA.msg.content}`)
          return 0
        } else if (resPostQA.err !== 0) {
          store.dispatch('setLoading', false)
          message.success(resPostQA.msg)
        }
      } catch (err) {
        store.dispatch('setLoading', false)
        console.log('catch', err)
      }
    },
    // 清除資料
    cleanProductsStates({ commit }) {
      commit('CLEAN_PRODUCTS_STATES')
    }
  },
  getters: {
    productDetail(state) {
      return state.productDetail
    },
    productQuestions(state) {
      return state.productQuestions
    },
    allProducts(state) {
      return state.allProducts
    },
    hotProducts(state) {
      return state.hotProducts
    },
    categories(state) {
      return state.categories
    },
    minusOnePage(state) {
      return state.minusOnePage
    }
  }
}
