import { GetOrders,PayOrders,CancelOrders,ReturnOrders,GetOrderItem } from '@/apis/Orders'
import store from '@/store'
import message from 'ant-design-vue/es/message'

export default {
  state: () => ({
    orders: [],
    order: '',
    returnShowBankTrigger: false,
    orderMinusOnePage: undefined,
    orderZeroPage: undefined,
    orderItem: {}
  }),
  mutations: {
    CLEAN_ORDERS(state) {
      state.orders = []
    },
    SET_ORDERS(state, payload) {
      state.orders = [...state.orders,...payload]
    },
    SET_RETURN_SHOWBANK_TRIGGER(state, payload) {
      state.returnShowBankTrigger = payload
    },
    SET_ORDER_MINUS_ONE_PAGE(state) {
      state.orderMinusOnePage = !state.orderMinusOnePage
    },
    SET_PAYMENT_HTML(state,payload) {
      state.paymentHtml = payload
    },
    SET_ORDERITEM(state, payload) {
      state.orderItem = payload
    },
    SET_ORDER_ZERO_PAGE(state) {
      state.orderZeroPage = !state.orderZeroPage
    }
  },
  actions: {
    // 全部 訂單紀錄
    async getOrders({ commit }, data) {
      store.dispatch('setLoading', true)
      try {
        const res = await GetOrders(data)
        if ((res.err === 0) & (res.msg.length > 0)) {
          commit('SET_ORDERS', res.msg)
          store.dispatch('setLoading', false)
        }
        else if (res.msg.length == 0) {
          commit('SET_ORDER_MINUS_ONE_PAGE')
          store.dispatch('setLoading', false)
        }
        else if (res.err === 1) {
          message.error(res.msg)
          store.dispatch('setLoading', false)
          commit('SET_ORDER_MINUS_ONE_PAGE')
        }
      } catch (err) {
        commit('SET_ORDER_MINUS_ONE_PAGE')
        store.dispatch('setLoading', false)
        console.log(err)
      }
    },
    // 結帳
    async orderPayment( { commit } , no) {
      store.dispatch('setLoading', true)
      try {
        const res = await PayOrders(no)
        if (res.err == 0) {
          store.dispatch('setLoading', false)
          console.log(res.msg)
          document.open('text/html');
          document.write(`
            <script>
              window.onpageshow = function(event) {
                if (event.persisted) {
                  window.location = '/orders'
                }
              }
            </script>
          `);
          document.write(res.msg);
          document.close();

          commit('SET_PAYMENT_HTML', res.msg)
          // this.$router.push('/Success')
        }
        else if (res.err == 1){
          store.dispatch('setLoading', false)
          message.error(res.msg)
          console.log(res.msg)
          this.$router.push('/Fail')
        }
      } catch (err) {
        store.dispatch('setLoading', false)
        console.log(err)
      }
    },
    // 取消
    async cancelOrders( _ , no) {
      store.dispatch('setLoading', true)
      try {
        const res = await CancelOrders(no)
        if (res.err === 0) {
          message.success('取消成功')
        } else if (res.err === 1) {
          message.error(res.msg)
          this.setLoading(false)
        }} catch (err) {
        store.dispatch('setLoading', false)
        console.log(err)
      }
    },
    // 退貨
    async returnOrders( {commit} , {no,data}) {
      store.dispatch('setLoading', true)
      try {
        const res = await ReturnOrders({no,data})
        if (res.err === 0) {
          message.success('退貨申請已送出')
          store.dispatch('setLoading', false)
          commit('SET_RETURN_SHOWBANK_TRIGGER', false)//填寫內容關閉
        } else if (res.err === 1) {
          message.error(res.msg)
          commit('SET_RETURN_SHOWBANK_TRIGGER', true) //填寫內容維持開啟
          store.dispatch('setLoading', false)
        }
      } catch (err) {
        store.dispatch('setLoading', false)
        console.log(err)
      }
    },
    async cleanOrders({commit} ){
      commit('CLEAN_ORDERS') //清空紀錄
      commit('SET_ORDER_ZERO_PAGE')
    },
    async getOrderItem({commit}, orderNo) {
      commit('SET_ORDERITEM', {})
      store.dispatch('setLoading', true)
      try {
        const res = await GetOrderItem(orderNo)
        if(res.err === 0) {
          store.dispatch('setLoading', false)
          commit('SET_ORDERITEM', res.msg)
        } else if (res.err === 1) {
          message.error('取得訂單資訊失敗')
          store.dispatch('setLoading', false)
        }
      } catch(err) {
        store.dispatch('setLoading', false)
        console.log(err)
      }
    }
  },
  getters: {
    orders(state) {
      return state.orders
    },
    SET_RETURN_TRIGGER(state){
      return state.returnShowBankTrigger
    },
    orderMinusOnePage(state) {
      return state.orderMinusOnePage
    },
    orderZeroPage(state) {
      return state.orderZeroPage
    },
    orderItem(state) {
      return state.orderItem
    }
  }
}
