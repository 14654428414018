export default {
  state: () => ({
    activeTab: null
  }),
  mutations: {
    SET_KEY (state, { key, value }) {
      state[key] = value
    },
    REMOVE_KEY(state, { key, value = null }) {
      state[key] = value
    }
  }
}
