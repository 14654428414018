//import store from '../index'
import { GetHomeBanner, GetProductBanner } from '@/apis/Banner'
import { defaultBannerList } from '@/data/defaultData'

export default {
    state: () => ({
        homeBannerList: [],
        productBannerList: []
    }),
    mutations: {
        // SET_ADDRESSES(state, payload) {
        //     state.addresses = payload
        // }
        SET_HOME_BANNER(state, payload) {
            state.homeBannerList = payload
        },
        SET_PRODUCT_BANNER(state, payload) {
            state.productBannerList = payload
        }
    },
    actions: {
        //取得首頁Banner
        async getHomeBanner({ commit }) {
            try {
                const response = await GetHomeBanner()
                if (response.err === 0) {
                    console.log(response.msg)
                    if (response.msg.length > 0) {
                        await commit('SET_HOME_BANNER', response.msg)
                    } else {
                        await commit('SET_HOME_BANNER', defaultBannerList)
                    }
                }
                else {
                    console.log(response.errors)
                    await commit('SET_HOME_BANNER', defaultBannerList)
                }
            } catch (err) {
                console.log('catch error', err)
                await commit('SET_HOME_BANNER', defaultBannerList)
            }
        },
        //取得商品頁Banner
        async getProductBanner({ commit }) {
            try {
                const response = await GetProductBanner()
                if (response.err === 0) {
                    console.log(response.msg)
                    if (response.msg.length > 0) {
                        await commit('SET_PRODUCT_BANNER', response.msg)
                    } else {
                        await commit('SET_PRODUCT_BANNER', [])
                    }
                }
                else {
                    console.log(response.errors)
                    await commit('SET_PRODUCT_BANNER', [])
                }
            } catch (err) {
                console.log('catch error', err)
                await commit('SET_PRODUCT_BANNER', [])
            }
        }
    },
    getters: {
        homeBannerList(state) {
            return state.homeBannerList
        },
        productBannerList(state) {
            return state.productBannerList
        },
    }
}