import request,{formRequest} from '@/utils/request'

/**
 * @description 32.取得訂單清單
 * @queryString Query String
 * @property {number} page 頁數
 * @property {number} size 筆數
 * @property {number} status 狀態
 * @property {number} 0: 未付款
 * @property {number} 1: 已付款
 * @property {number} 2: 交易完成
 * @property {number} 3: 部分出貨(訂單狀態)
 * @property {number} 10: 用戶請求取消
 * @property {number} 11: 交易關閉(同意取消，並且已退款)
 * @property {number} 12: 交易關閉(同意取消，並且已退款及退貨)
 * @property {number} 13: 部分退貨(訂單狀態)
 * @property {number} 14: 用戶請求退貨(退貨中)
 */
export function GetOrders(data) {
  return formRequest({
    url: '/orders',
    method: 'get',
    params: data
  })
}

//結帳
export function PayOrders(no) {
  return request({
    url: `/orders/${no}/payment`,
    method: 'post'
  })
}

//取消
export function CancelOrders(no) {
  return request({
    url: `/orders/${no}/cancel`,
    method: 'patch',
    params: {},
  })
}

//退貨
export function ReturnOrders({ no, data}) {
  return formRequest({
    url: `/orders/${no}/return`,
    method: 'patch',
    data
  })
}

//取訂單資料
export function GetOrderItem(orderNo) {
  return request({
    url: `/orders/${orderNo}/getItem`,
    method: 'post'
  })
}