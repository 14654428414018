import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '../store'
//import trackService from '../utils/trackService'

const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}

// const pageUserTrack = async ({ trackedTitle = '' } = {}) => {
//   const { meta } = router.currentRoute

//   const pageTitle = trackedTitle || meta.trackedTitle

//   console.log(meta)
//   console.log(pageTitle)

//   if(Vue.gtm && pageTitle) {
//       Vue.gtm.trackEvent({
//           event: 'content-view',
//           pageTitle: pageTitle
//       })
//   }
// }

Vue.use(VueRouter)

const routes = [
  {
    name: 'home',
    path: '/',
    components: {
      default: () => import('../views/Home.vue'),
      LogoutPage: () => import('../views/Join.vue')
    },
    meta: {
      requiresAuth: false
    },
    // 已登入
    children: [
      {
        name: 'dashboard',
        path: 'dashboard',
        component: () => import('../views/Dashboard/index.vue'),
        meta: {
          requiresAuth: true,
          trackedTitle: 'dashboard'
        }
      },
      {
        name: 'status',
        path: 'status',
        component: () => import('../views/Status/index.vue'),
        meta: {
          requiresAuth: true,
          trackedTitle: 'status'
        }
      },
      {
        name: 'personal',
        path: 'personal',
        component: () => import('../views/Personal/Personal.vue'),
        meta: {
          requiresAuth: true,
          trackedTitle: 'personal'
        }
      },
      {
        name: 'changePassword',
        path: 'changePassword',
        component: () => import('../views/Personal/ChangePassword.vue'),
        meta: {
          requiresAuth: true,
          trackedTitle: 'changePassword'
        }
      },
      {
        name: 'products',
        path: 'products',
        components: {
          default: () => import('../views/Products/index.vue')
        },
        meta: {
          requiresAuth: true,
          // needPassedStatus: true,
          trackedTitle: 'products'
        },
        children: [
          {
            name: 'productDetail',
            path: ':id',
            components: {
              list: () => import('../views/Products/ProductDetail.vue')
            },
            meta: {
              requiresAuth: true,
              needPassedStatus: true,
              trackedTitle: 'productDetail'
            }
          }
        ]
      },
      {
        name: 'cart',
        path: 'cart',
        component: () => import('../views/Cart/index.vue'),
        meta: {
          requiresAuth: true,
          needPassedStatus: true,
          trackedTitle: 'cart'
        }
      },
      {
        name: 'payment',
        path: 'payment',
        component: () => import('../views/Payment/index.vue'),
        meta: {
          requiresAuth: true,
          needPassedStatus: true,
          trackedTitle: 'payment'
        }
      },
      {
        name: 'orders',
        path: 'orders',
        component: () => import('../views/Orders/index.vue'),
        meta: {
          requiresAuth: true,
          needPassedStatus: true,
          trackedTitle: 'orders'
        }
      },
      {
        name: 'addresses',
        path: 'addresses',
        component: () => import('../views/Addresses/index.vue'),
        meta: {
          requiresAuth: true,
          trackedTitle: 'addresses'
        }
      },
      {
        name: 'addressesAdd',
        path: 'address/add',
        component: () => import('../views/Addresses/HomeAddressPage.vue'),
        meta: {
          requiresAuth: true,
          trackedTitle: 'addressesAdd'
        }
      },
      {
        name: 'addressesEdit',
        path: 'address/edit/:id',
        component: () => import('../views/Addresses/HomeAddressPage.vue'),
        meta: {
          requiresAuth: true,
          trackedTitle: 'addressesEdit'
        }
      },
            {
        name: 'qa',
        path: 'qa',
        component: () => import('../views/Qa/index.vue'),
        meta: {
          requiresAuth: true,
          trackedTitle: 'qa'
        }
      }
    ]
  },
  {
    name: 'login',
    path: '/login',
    components: { LogoutPage: () => import('../views/Auth/Login.vue') },
    meta: {
      requiresAuth: false,
      trackedTitle: 'login'
    }
  },
  {
    name: 'terms',
    path: '/terms',
    components: { LogoutPage: () => import('../views/Auth/Terms.vue') },
    meta: {
      requiresAuth: false,
      trackedTitle: 'terms'
    }
  },
  {
    name: 'register',
    path: '/register',
    components: { LogoutPage: () => import('../views/Auth/Register.vue') },
    meta: {
      requiresAuth: false,
      trackedTitle: 'register'
    }
  },
  {
    name: 'reset',
    path: '/reset',
    components: { LogoutPage: () => import('../views/Auth/Reset.vue') },
    meta: {
      requiresAuth: false,
      trackedTitle: 'reset'
    }
  },
  {
    name: 'success',
    path: '/payment/success', //不是children 因為payment沒有cart資料會跳轉
    components: {
      default: () => import('../views/Payment/Success.vue'),
      LogoutPage: () => import('../views/Payment/Success.vue')
    },
    meta: {
      freeView: true,
      requiresAuth: false,
      trackedTitle: 'success'
    }
  },
  {
    name: 'failure',
    path: '/payment/failure', //不是children 因為payment沒有cart資料會跳轉
    components: {
      default: () => import('../views/Payment/Failure.vue'),
      LogoutPage: () => import('../views/Payment/Failure.vue')
    },
    meta: {
      freeView: true,
      requiresAuth: false,
      needPassedStatus: true,
      trackedTitle: 'failure'
    }
  },
  {
    name: 'errorPage', //可以寫query參數錯誤訊息到這
    path: '/errorPage/',
    components: {
      default: () => import('../views/Errors/ErrorPage.vue'),
      LogoutPage: () => import('../views/Errors/ErrorPage.vue')
    },
    meta: {
      freeView: true,
      requiresAuth: false,
      trackedTitle: 'errorPage'
    }
  },
  {
    name: 'error',
    path: '/*', //404
    components: {
      default: () => import('../views/Errors/index.vue'),
      LogoutPage: () => import('../views/Errors/index.vue')
    },
    meta: {
      freeView: true,
      requiresAuth: false,
      trackedTitle: 'error'
    }
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
  routes
})

router.beforeEach((to, from, next) => {
  // eslint-disable-next-line no-undef
  let hasLogin = store.getters.hasToken
  const isNeedPassedStatus = to.matched.some(m => m.meta.needPassedStatus) //這個頁面1審核過的人才能瀏覽
  const isRequiresAuth = to.matched.some(m => m.meta.requiresAuth)
  const freeView = to.matched.some(m => m.meta.freeView) //不用檢查的

  // 標題
  if (to.meta.title) {
    document.title = to.meta.title
  }
  switch (isRequiresAuth) {
    case true:
      if (!hasLogin) {
        //沒有登入但是需要驗證,去首頁
        console.log(
          '沒有登入',
          'to=',
          to.fullPath,
          '| from=',
          from.fullPath,
          '| next=',
          next.fullPath,
          '| hasLogin:',
          hasLogin,
          '| `isRequiresAuth`:',
          isRequiresAuth,
          '| userStatus',
          store.getters.userStatus
        )
        
        sessionStorage.setItem('afterlogin-to', to.fullPath)

        next('/login')
        break
      }

      if (isNeedPassedStatus & (store.getters.userStatus == 1)) {
        // if(sessionStorage.getItem('afterlogin-to') != null) {
        //   next(sessionStorage.getItem('afterlogin-to'))
        //   sessionStorage.removeItem('afterlogin-to')
        // } else {
          next()
        //}
        break
      } else if (isNeedPassedStatus & (store.getters.userStatus != 1)) {
        console.log('審核未通過導回dashboard', store.getters.userStatus)
        // next('/dashboard')
        next({ path:'/products', query: { selectedKey: 'all' }})
      }
      console.log('isNeedPassedStatus非狀態1或-1的情況',
        '登入中',
        'to=',
        to.fullPath,
        '| from=',
        from.fullPath,
        '| next=',
        next.fullPath,
        '| hasLogin:',
        hasLogin,
        '| isRequiresAuth:',
        isRequiresAuth,
        '| userStatus',
        store.getters.userStatus
      )

      next()
      break

    case false:
      if (hasLogin & !freeView) {
        console.log(
          '登入中',
          'to=',
          to.fullPath,
          '| from=',
          from.fullPath,
          '| next=',
          next.fullPath,
          '| hasLogin:',
          hasLogin,
          '| isRequiresAuth:',
          isRequiresAuth,
          '| userStatus',
          store.getters.userStatus
        )
        console.log('freeView')
        //next('/dashboard')
        next({ path:'/products', query: { selectedKey: 'all' }})
        break
      } else next()
      console.log('沒有登入', 'to=', to.fullPath, '| from=', from.fullPath, '| next=', next.fullPath, '| hasLogin:', hasLogin, '| `isRequiresAuth`:', isRequiresAuth)
  }
})

// router.afterEach(() => {
//   setTimeout(() => {
//     pageUserTrack()
//   },500)
// })

export default router
