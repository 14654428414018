import Vue from 'vue'
import App from './App.vue'
import VueGtm  from '@gtm-support/vue2-gtm'
import router from './router'
import store from './store'
import '@/library/antd.js'
import '@/assets/styles/style.css'
import '@/assets/styles/antd.less'
//import { VueReCaptcha } from 'vue-recaptcha-v3'
import vueAwesomeCountdown from 'vue-awesome-countdown'
import { library } from '@fortawesome/fontawesome-svg-core'
import { faChevronRight, faClone } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import VueClipboard from 'vue-clipboard2'
import './utils/cmmfilter'

library.add(faChevronRight)
library.add(faClone)
Vue.component('font-awesome-icon', FontAwesomeIcon)

Vue.config.productionTip = false
// 倒數計時
Vue.use(vueAwesomeCountdown, 'vac')
// Vue.use(VueReCaptcha, { siteKey: '6LdEWV0bAAAAACtX9PdNLvxHgYs7yEpTpF8_G1c4', loaderOptions: {
//   autoHideBadge: true
// } })
Vue.use(VueClipboard)

Vue.use(VueGtm, {
    id: process.env.VUE_APP_GTM_CODE,
    debug: false
  }
)

new Vue({
  router,
  store,
  // VueAwesomeSwiper,
  render: (h) => h(App)
}).$mount('#app')
