import router from '../../router'
import store from '../index'
import { GetAddresses,PostAddresses,DeleteAddresses, PatchAddresses } from '@/apis/Addresses'
import * as root from '../index'
import message from 'ant-design-vue/es/message'

export default {
  state: () => ({
    addresses:''
  }),
  mutations: {
    SET_ADDRESSES(state, payload) {
      state.addresses = payload
  }
  },
  actions: {
    // 取得地址清單
    async getAddressesData({ commit }, data) {
      console.log('data',data)
      root.default.commit('SET_LOADING', true)
      try {
        const response = await GetAddresses(data)
        if (response.err === 0) {
          //清單取得成功
          console.log(response.msg)
          await commit('SET_ADDRESSES', response.msg)
          await root.default.commit('SET_LOADING', false)
        }
        else{
          message.error(response.msg)
          console.log(response.errors)
        }
      } catch (err) {
        console.log('catch error',err)
        message.error(err)
        root.default.commit('SET_LOADING', false)
      }
    },

    // 新增宅配地址
    async addHomeAddress({ commit }, { data, handleSuccessCallback }) {
      console.log('addHomeAddress Params',data)
      root.default.commit('SET_LOADING', true)
      try {
        const response = await PostAddresses(data)
        if (response.err === 0) {
          //新增宅配成功
          console.log('response.msg',response.msg)
          handleSuccessCallback()
          await commit('SET_ADDRESSES', response.msg)
          await root.default.commit('SET_LOADING', false)
          await commit('SET_KEY', { key: 'activeTab',value: '2' }, { root: true })
          await router.push({ name: 'personal' })
          await message.success('成功新增宅配地址')
          
        }
        else{
          message.error(response.msg)
          console.log(response.errors)
        }
      } catch (err) {
        console.log('catch error',err)
        message.error(err)
      }
      root.default.commit('SET_LOADING', false)
    },

    // 編輯宅配地址
    async editHomeAddress({ commit }, {id ,formData, handleSuccessCallback}) {
      console.log('editHomeAddress id', id)
      console.log('editHomeAddress data', formData)
      root.default.commit('SET_LOADING', true)
      try {
        const response = await PatchAddresses(id,formData)
        if (response.err === 0) {
          //編輯成功
          handleSuccessCallback()
          await commit('SET_ADDRESSES', response.msg)
          console.log('編輯地址回傳msg',response.msg)
          await root.default.commit('SET_LOADING', false)
          await commit('SET_KEY', { key: 'activeTab',value: '2' }, { root: true })
          await router.push({ name: 'personal' })
          await message.success('成功編輯宅配地址')
        }
        if (response.err === 1) {
          //編輯錯誤
          await message.error(response.msg)
        }
      } catch (err) {
        console.log('catch error',err)
        root.default.commit('SET_LOADING', false)
      }
      await root.default.commit('SET_LOADING', false)
    },

     // 刪除地址
    async deleteAddressData(_,id) {
      console.log('id',id)
      root.default.commit('SET_LOADING', true)
      try {
        const response = await DeleteAddresses(id)
        if (response.err === 0) {
          await root.default.commit('SET_LOADING', false)
          await store.dispatch('getAddressesData')
          await message.success('成功刪除地址')
        }
        else{
          message.error(response.msg)
          console.log(response.errors)
        }
      } catch (err) {
        console.log('catch error',err)
        message.error(err)
        root.default.commit('SET_LOADING', false)
      }
    },
  },
  getters: {
    addresses(state) {
      return state.addresses
    },
  }
}


