<template>
  <div id="app">
    <loading :isLoading="loading" />
    <template v-if="!isLogin">
      <router-view name="LogoutPage"></router-view>
    </template>
    <template v-if="isLogin">
      <keep-alive include="products">
        <router-view></router-view>
      </keep-alive>
    </template>
  </div>
</template>
<style>
#app {
  -webkit-overflow-scrolling: touch;
  height: inherit;
}
</style>
<script>
import { mapGetters, mapActions } from 'vuex'
import Loading from '@/components/Loading.vue'

export default {
  components: { Loading },
  methods:{
    ...mapActions(['checkTokens', 'setWindowWidth']),
    initialize() {
      this.detectWindowWidth();
    },
    detectWindowWidth() {
      this.setWindowWidth(window.innerWidth);
    }
  },
  computed: {
    ...mapGetters(['loading', 'isLogin', 'rootState'])
  },
  watch: {
    '$route': {
      immediate: true,
      handler(v) {
        this.$nextTick(() => {
          const recaptcha = this.$recaptchaInstance
          if(!recaptcha) {
            return;
          }
          if(v.name !== 'register') {
            recaptcha.hideBadge()
          } else {
            recaptcha.showBadge()
          }
        })
      }
    },
  },
  created(){
    this.checkTokens();
    this.initialize();
    window.addEventListener("resize", this.detectWindowWidth);
  },
  destroyed() {
    window.removeEventListener("resize", this.detectWindowWidth);
  },
}
</script>
