import router from '../../router'
import { GetUserBenefit, GetUser, UpdateUser, GetUserCoco, GetUserCocoHistory } from '@/apis/User'
import * as root from '../index'
import message from 'ant-design-vue/es/message'
//import { GetUserCoco } from '../../apis/User'

export default {
  state: () => ({
    user: {},
    userStatus: localStorage.getItem('local-status'),
    userBenefit: {},
    userCoco: 0,
    userCocoHistory: []
  }),
  mutations: {
    SET_USER(state, payload) {
      state.user = payload

      localStorage.setItem('local-status', payload.status)
      // 記住我 status
      if (root.default.state.auth.rememberMe) {
        console.log('remember-me, set-local-status')
      }
      // 不用記住我 status
      else if (!root.default.state.auth.rememberMe) {
        console.log('not remember-me, set-local-status')
        // localStorage.removeItem('local-status')
        // 登入成功
        // sessionStorage.setItem('session-status', payload.status)
      }

      state.userStatus = payload.status

      // // 記住我 status
      // if (root.default.state.auth.rememberMe) {
      //   localStorage.setItem('local-status', payload.status)
      //   console.log('remember-me, set-local-status')
      // }
      // // 不用記住我 status
      // else if (!root.default.state.auth.rememberMe) {
      //   localStorage.removeItem('local-status')
      //   sessionStorage.setItem('session-status', payload.status)
      // }
    },

    SET_USER_BENEFIT(state, payload) {
      state.userBenefit = payload
    },
    SET_USER_COCO(state, payload) {
      state.userCoco = payload
    },
    SET_USER_COCO_HISTORY(state, payload) {
      state.userCocoHistory = payload
    },
  },
  actions: {
    async getUser({ commit }) {
      root.default.dispatch('setLoading', true)
      try {
        const response = await GetUser()
        if (response.err === 0) {
          root.default.dispatch('setLoading', false)
          commit('SET_USER', response.msg)
        } else if (response.err === 1) {
          root.default.dispatch('setLoading', false)
        }
      } catch (err) {
        root.default.dispatch('setLoading', false)
        console.log(err)
      }
    },
    async getUserWithoutLoading({ commit }) {
      try {
        const response = await GetUser()
        if (response.err === 0) {
          commit('SET_USER', response.msg)
        } else if (response.err === 1) {
          root.default.dispatch('setLoading', false)
        }
      } catch (err) {
        console.log(err)
      }
    },
    async updateUser({ dispatch }, data) {
      console.log(data)
      try {
        root.default.dispatch('setLoading', true)
        const response = await UpdateUser(data)
        if (response.err === 0) {
          root.default.dispatch('setLoading', false)
          dispatch('getUser')
          message.success('更新成功')
        } else if (response.err === 1) {
          root.default.dispatch('setLoading', false)
          message.error(response.msg)
        } else if (response.err === 2) {
          message.error(response.msg)
          router.push({ name: 'errorPage', query: { desc: 1, title: '帳號異常' } })
          root.default.dispatch('setLoading', false)
        }
      } catch (err) {
        message.error(`使用者個人資料更新失敗 err: ${JSON.stringify(err)}`)
        root.default.dispatch('setLoading', false)
        console.log(err)
      }
    },
    async getUserBenefit({ commit }) {
      console.log('vuex,getUserBenefit')
      root.default.dispatch('setLoading', true)
      try {
        const response = await GetUserBenefit()
        if (response.err === 0) {
          root.default.dispatch('setLoading', false)
          commit('SET_USER_BENEFIT', response.msg)
        } else if (response.err === 1) {
          root.default.dispatch('setLoading', false)
        }
      } catch (err) {
        root.default.dispatch('setLoading', false)
        console.log(err)
      }
    },
    async getUserCoco({ commit }) {
      try {
        const response = await GetUserCoco()
        if (response.err === 0) {
          commit('SET_USER_COCO', response.msg.cash)
        }
      } catch (err) {
        message.error('取得COCO幣異常')
        commit('SET_USER_COCO', 0)
      }
    },
    async getUserCocoHistory({ commit }) {
      try {
        const response = await GetUserCocoHistory()
        if (response.err === 0) {
          commit('SET_USER_COCO_HISTORY', response.msg)
        }
      } catch (err) {
        message.error('取得COCO幣列表失敗')
        commit('SET_USER_COCO_HISTORY', [])
      }
    }
  },
  getters: {
    user(state) {
      return state.user
    },
    userStatus(state) {
      return state.userStatus
    },
    userBenefit(state) {
      return state.userBenefit
    },
    userCoco(state) {
      return state.userCoco
    },
    userCocoHistory(state) {
      return state.userCocoHistory
    },
  }
}
