import request,{formRequest} from '@/utils/request'

// 24.取得購物車
export function GetCart() {
  return request({
    url: '/cart',
    method: 'get',
  })
}

// 25.在購物車中新增商品
export function PostCart(data) {
  return formRequest({
    url: '/cart',
    method: 'post',
    data
  })
}

// 26.在購物車中更新商品
export function PatchCart(id,data) {
  return formRequest({
    url: `/cart/${id}`,
    method: 'patch',
    data
  })
}

// 27.在購物車中新增商品
export function DeleteCart(id) {
  return formRequest({
    url: `/cart/${id}`,
    method: 'delete',
  })
}

// 28.在購物車結帳商品
export function PostPaymentCart(id,data) {
  console.log(data)
  return formRequest({
    url: `/cart/`+`${id}/`+`payment`,
    method: 'post',
    data
  })
}

